
















































import Component, { mixins } from 'vue-class-component';
// import { authModule, likeModule } from '@/store';
import { Prop } from 'vue-property-decorator';
import moment from 'moment';
import DataFormat from '@/shared/utils/dataFormat';
import * as firebase from 'firebase/app';

@Component
export default class ProductList extends mixins(DataFormat) {
  @Prop()
  public dataList!: any;

  public endState(date: any) {
    const now = moment();
    const endDate = moment(date);
    const result = endDate.diff(now, 'days');
    if (result >= 0) {
      return false;
    } else {
      return true;
    }
  }

  // public likeStatus(state, id, index) {
  //   if (!this.isLogin) {
  //     this.$q.notify('로그인이 필요합니다.');
  //     this.$modal.show('login');
  //   } else {
  //     const params = {
  //       target: 'product',
  //       target_id: id
  //     }
  //     if (state) {
  //       // likeModule.fetchLikeDelete(params).then((res) => {
  //       //   if (res.status) {
  //       //     this.$q.notify('좋아요를 취소하셨습니다.');
  //       //     this.dataList[index].like_state = !this.dataList[index].like_state;
  //       //   }
  //       // });
  //     } else {
  //       // likeModule.fetchLike(params).then((res) => {
  //       //   if (res.status) {
  //       //     this.$q.notify('티켓을 좋아요 하였습니다.');
  //       //     this.dataList[index].like_state = !this.dataList[index].like_state;
  //       //   }
  //       // });
  //     }
  //   }
  // }

  public fastReserve(item, time, sIndex, listIndex) {
    this.eventClickBannerTimeblock(listIndex, sIndex);
    this.$router.push({ name: 'product', params: {
      productId: item.id,
      reserveTime: time,
      reserveDate: String(this.$route.query.reserve_date ? this.$route.query.reserve_date : this.$route.query.schedule_date ? this.$route.query.schedule_date : this.$moment().add('1', 'days').format('YYYY-MM-DD')),
      person: String(this.$route.query.party_size ? this.$route.query.party_size : '2'),
      timeCheck: sIndex
    }});
  }

  public eventSelectContent(index) {
    const eventName = 'select_content';
    const eventParams: any = {
      type: 'product',
      id: this.dataList[index].id.toString(),
      name: this.dataList[index].title,
      party_size: this.$route.query.party_size ? this.$route.query.party_size : null,
      reserve_date: this.$route.query.reserve_date ? this.$route.query.reserve_date : this.$route.query.schedule_date ? this.$route.query.schedule_date : null,
      start_time: this.$route.query.start_time ? this.$route.query.start_time : null,
      end_time: this.$route.query.end_time ? this.$route.query.end_time : null
    }
    firebase.analytics().logEvent(eventName, eventParams);
  }
  public eventClickBannerTimeblock(listIndex, sIndex) {
    const eventName = 'click_banner_timeblock';
    const eventParams: any = {
      time: this.dataList[listIndex].schedules[sIndex].time,
      type: 'product',
      id: this.dataList[listIndex].id.toString(),
      name: this.dataList[listIndex].name,
      party_size: this.$route.query.party_size ? this.$route.query.party_size : null,
      reserve_date: this.$route.query.reserve_date ? this.$route.query.reserve_date : this.$route.query.schedule_date ? this.$route.query.schedule_date : null,
      start_time: this.$route.query.start_time ? this.$route.query.start_time : null,
      end_time: this.$route.query.end_time ? this.$route.query.end_time : null
    }
    firebase.analytics().logEvent(eventName, eventParams);
  }

  public pageMove(id, index) {
    this.eventSelectContent(index);
    this.$router.push({
      name: 'product',
      params: {
        productId: id,
        reserveDate: String(this.$route.query.reserve_date ? this.$route.query.reserve_date : this.$route.query.schedule_date ? this.$route.query.schedule_date : this.$moment().add('1', 'days').format('YYYY-MM-DD')),
        person: String(this.$route.query.party_size ? this.$route.query.party_size : '2')
      } });
  }

  // get isLogin() {
  //   return authModule.isLogin;
  // }
}
