










































































import { Vue, Component, Prop } from 'vue-property-decorator';
// import { authModule, likeModule } from '@/store';
import { mixins } from 'vue-class-component';
import DataFormat from '@/shared/utils/dataFormat';
import { EventBus } from '@/config/config';
import moment from 'moment';
import * as firebase from 'firebase/app';

@Component
export default class PlaceList extends mixins(DataFormat) {
  @Prop({
    required: true
  })
  public dataList!: any;
  @Prop({
    required: true
  })
  public type!: string;
  @Prop()
  public viewType!: string;
  @Prop()
  public keyword!: string;

  // get isLogin() {
  //   return authModule.isLogin;
  // }

  public eventSelectContent(index) {
    const eventName = 'select_content';
    const eventParams: any = {
      type: 'place',
      id: this.dataList[index].id.toString(),
      name: this.dataList[index].name,
      party_size: this.$route.query.party_size ? this.$route.query.party_size : null,
      reserve_date: this.$route.query.reserve_date ? this.$route.query.reserve_date : this.$route.query.schedule_date ? this.$route.query.schedule_date : null,
      start_time: this.$route.query.start_time ? this.$route.query.start_time : null,
      end_time: this.$route.query.end_time ? this.$route.query.end_time : null
    }
    firebase.analytics().logEvent(eventName, eventParams);
  }
  public eventClickBannerTimeblock(listIndex, sIndex) {
    const eventName = 'click_banner_timeblock';
    const eventParams: any = {
      time: this.dataList[listIndex].schedules[sIndex].time,
      type: 'place',
      id: this.dataList[listIndex].id.toString(),
      name: this.dataList[listIndex].name,
      party_size: this.$route.query.party_size ? this.$route.query.party_size : null,
      reserve_date: this.$route.query.reserve_date ? this.$route.query.reserve_date : this.$route.query.schedule_date ? this.$route.query.schedule_date : null,
      start_time: this.$route.query.start_time ? this.$route.query.start_time : null,
      end_time: this.$route.query.end_time ? this.$route.query.end_time : null
    }
    firebase.analytics().logEvent(eventName, eventParams);
  }
  public eventSearch() {
    const eventName = 'search';
    const eventParams: any = {
      type: 'auto',
      keyword: this.keyword
    }
    firebase.analytics().logEvent(eventName, eventParams);
  }

  public pageMove(data, index) {
    this.eventSelectContent(index);
    this.eventSearch();
    if (this.type === 'search') {
      if (this.viewType === 'search') {
        if (data.schedule_target === 'product') { // 타임블럭이 있는 티켓
          this.$router.push({
            name: 'product',
            params: {
              productId: data.schedule_target_id,
              reserveDate: String(this.$route.query.reserve_date ? this.$route.query.reserve_date : this.$route.query.schedule_date ? this.$route.query.schedule_date : this.$moment().add('1', 'days').format('YYYY-MM-DD')),
              person: String(this.$route.query.party_size ? this.$route.query.party_size : '2')
            } });
        } else {
          this.$router.push({
            name: 'place',
            params: {
              placeId: data.id,
              reserveDate: String(this.$route.query.reserve_date ? this.$route.query.reserve_date : this.$route.query.schedule_date ? this.$route.query.schedule_date : this.$moment().add('1', 'days').format('YYYY-MM-DD')),
              person: String(this.$route.query.party_size ? this.$route.query.party_size : '2')
            }
          });
        }
      } else {
        this.$router.push({
          name: 'place',
          params: {
            placeId: data.id,
            reserveDate: String(this.$route.query.reserve_date ? this.$route.query.reserve_date : this.$route.query.schedule_date ? this.$route.query.schedule_date : this.$moment().add('1', 'days').format('YYYY-MM-DD')),
            person: String(this.$route.query.party_size ? this.$route.query.party_size : '2')
          }
        });
      }
    } else {
      this.$router.replace({ name: 'review-write', query: { placeId: data.id }});
    }
  }

  // public likeStatus(state, id, index) {
  //   if (!this.isLogin) {
  //     this.$q.notify('로그인이 필요합니다.');
  //     this.$modal.show('login');
  //   } else {
  //     const params = {
  //       target: 'place',
  //       target_id: id
  //     };
  //     if (state) {
  //       // likeModule.fetchLikeDelete(params).then((res) => {
  //       //   if (res.status) {
  //       //     this.$q.notify('좋아요를 취소하셨습니다.');
  //       //     this.dataList[index].like_state = !this.dataList[index].like_state;
  //       //   }
  //       // });
  //     } else {
  //       // likeModule.fetchLike(params).then((res) => {
  //       //   if (res.status) {
  //       //     this.$q.notify('매장을 좋아요 하였습니다.');
  //       //     this.dataList[index].like_state = !this.dataList[index].like_state;
  //       //   }
  //       // });
  //     }
  //   }
  // }

  public fastTime(item, time, sIndex, listIndex) {
    this.eventClickBannerTimeblock(listIndex, sIndex);
    this.$router.push({ name: 'place', params: {
      placeId: item.id,
      reserveTime: time,
      reserveDate: String(this.$route.query.reserve_date ? this.$route.query.reserve_date : this.$route.query.schedule_date ? this.$route.query.schedule_date : this.$moment().add('1', 'days').format('YYYY-MM-DD')),
      person: String(this.$route.query.party_size ? this.$route.query.party_size : '2'),
      timeCheck: sIndex
    } });
    // if (item.schedule_target === 'product') {
    //   this.$router.push({ name: 'product', params: {
    //     productId: item.schedule_target_id,
    //     reserveTime: time,
    //     reserveDate: String(this.$route.query.reserve_date ? this.$route.query.reserve_date : this.$route.query.schedule_date ? this.$route.query.schedule_date : this.$moment().add('1', 'days').format('YYYY-MM-DD')),
    //     person: String(this.$route.query.party_size ? this.$route.query.party_size : '2'),
    //     timeCheck: sIndex
    //   } });
    // } else {
    //   this.$router.push({ name: 'place', params: {
    //     placeId: item.id,
    //     reserveTime: time,
    //     reserveDate: String(this.$route.query.reserve_date ? this.$route.query.reserve_date : this.$route.query.schedule_date ? this.$route.query.schedule_date : this.$moment().add('1', 'days').format('YYYY-MM-DD')),
    //     person: String(this.$route.query.party_size ? this.$route.query.party_size : '2'),
    //     timeCheck: sIndex
    //   } });
    // }
  }
}
