import Vue from 'vue';
import Component from 'vue-class-component';

export interface InfiniteHandlerState {
  loaded: () => void;
  complete: () => void;
}

@Component
export default class ListMixins extends Vue {
  public params: any = {
    // page
    offset: 1,
    // count
    limit: 20
  };
  // 검색 params
  public placeParams: any = {
    offset: 1,
    limit: 20
  };
  public productParams: any = {
    offset: 1,
    limit: 20
  };
  public isLoading: boolean = false;
  public type: string = '';

  public $_listMixins_infiniteHandler($state: InfiniteHandlerState) {
    if (this.isLoading) {
      setTimeout(() => {
        $state.loaded();
      }, 800);
      return;
    }

    this.params.offset += 1;
    if (this.type === 'place') this.placeParams.offset += 1;
    else if (this.type === 'product') this.productParams.offset += 1;
    this.$_listMixins_fetch($state);
  }

  public $_listMixins_fetch($state: InfiniteHandlerState) {
    throw new Error('구현하세요');
  }
}
