



















import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListMixins, { InfiniteHandlerState } from '@/components/list/ListMixins';
import * as firebase from 'firebase/app';

@Component
export default class BannerGuide extends mixins(ListMixins) {
  @Prop()
  public guideList!: any;

  public eventSelectContent(index) {
    const eventName = 'select_content';
    const eventParams: any = {
      type: 'banner',
      id: this.guideList.banner[index].id.toString(),
      name: this.guideList.banner[index].title
    }
    firebase.analytics().logEvent(eventName, eventParams);
  }
}
