





























































import Component, { mixins } from 'vue-class-component';
import { bannerModule, searchModule } from '@/store';
import PlaceList from '@/components/list/PlaceList.vue';
import ProductList from '@/components/list/ProductList.vue';
import BannerGuideList from '@/components/list/BannerGuideList.vue';
// import MapFilterButton from '@/components/option/MapFilterButton.vue';
// import PoingFilter from '@/components/option/Filter.vue';
// import PlaceMapModal from '@/pages/detail/PlaceMapModal.vue';
import ListMixins, { InfiniteHandlerState } from '@/components/list/ListMixins';
import { map } from 'lodash';
import { EventBus } from '@/config/config';
import dataFormat from '@/shared/utils/dataFormat';

@Component({
  components: { PlaceList, BannerGuideList, ProductList }
})
export default class DetailBanner extends mixins(ListMixins, dataFormat)  {
  public type: string = 'place';
  public bannerType: string = '';
  public buttonType: string = 'default';
  public params: any = {
    offset: 1,
    limit: 20,
    geo_location: localStorage.nowLocation,
    is_schedule: 1
  };
  public loadingStatus: boolean = false;
  public filterTag: any = [];
  public firstRandom: any = null;
  public isFilter: boolean = false;
  public reserveStatus: boolean = false;

  get title() {
    if (this.$route.query.service_title) {
      return this.$route.query.service_title;
    } else if (this.bannerType === 'single') {
      return this.banner && this.banner.meta.banner_detail_title
    } else if (this.bannerType === 'place' && this.params.sort === 'recommended') {
      return '추천 매장';
    } else if (this.bannerType === 'place' && this.$route.path === '/interest_place') {
      return '최근 관심을 가진 매장';
    } else if (this.bannerType === 'product' && this.params.sort === 'recommended') {
      return '추천 다이닝 티켓';
    } else if (this.params.sort === 'popularity') {
      return '인기 다이닝 티켓';
    } else {
      if (this.bannerGroup) {
        return this.bannerGroup.meta.service_title
      } else if (this.banner) {
        return this.banner.meta.banner_detail_title
      } else return ''
    }
  }

  get bannerGroup(): any {
    if (this.bannerType === 'group') return bannerModule.getDetailBannerGroup;
    else return false;
  }

  get banner(): any {
    if (this.bannerType === 'single') return bannerModule.getDetailBanner;
    else return false;
  }

  get placeList() {
    if (this.bannerType === 'place') {
      if (this.$route.path === '/interest_place') {
        return bannerModule.visitedList;
      }
      return searchModule.getPlaceSearch;
    } else return false;
  }

  get productList() {
    if (this.bannerType === 'product') return searchModule.getProductSearch;
    else return false;
  }

  get visited() {
    if (this.$cookies.get('poingVisited')) {
      const re = JSON.parse(this.$cookies.get('poingVisited'));
      return map(re, (i) => i.place_id).join();
    } else {
      return null;
    }
  }

  get mapList() {
    if (this.bannerGroup) {
      if (this.type === 'place') return this.bannerGroup.place;
      else if (this.type === 'product') return this.bannerGroup.product;
      else return this.buttonType = 'hidden';
    } else if (this.banner) {
      if (this.type === 'place') return this.banner.place;
      else if (this.type === 'product') return this.banner.product;
      else return '';
    } else if (this.placeList) {
      return this.placeList.data;
    } else if (this.productList) {
      return this.productList.data;
    } else {
      return '';
    }
  }

  public beforeRouteUpdate(to, from, next) {
    this.loadingStart();
    this.type = to.params.type;
    this.params = Object.assign(this.params, to.query);

    if (this.params !== 1) {
      this.params.offset = 1;
    }

    if (to.query.reserve_date) this.reserveStatus = true;

    if (to.query.group_id || to.query.id) {
      this.buttonType = 'single';
      if (to.query.group_id) {
        const params: any = {
          id: to.query.group_id,
          area_id: to.query.area_id,
          geo_location: localStorage.nowLocation,
          is_schedule: 1
        };
        if (to.query.reserve_date) {
          params.reserve_date = to.query.reserve_date;
          params.party_size = to.query.party_size;
          params.start_time = to.query.start_time;
          params.end_time = to.query.end_time;
        }
        this.bannerType = 'group';
        bannerModule.fetchDetailBannerGroup(params).then(() => {
          this.loadingEnd();
        });
      } else {
        const params: any = {
          id: to.query.id,
          is_schedule: 1
        }
        if (to.query.reserve_date) {
          params.reserve_date = to.query.reserve_date;
          params.party_size = to.query.party_size;
          params.start_time = to.query.start_time;
          params.end_time = to.query.end_time;
        }
        this.bannerType = 'single';
        bannerModule.fetchDetailBanner(params).then(() => {
          this.loadingEnd();
        });
      }
    } else if (to.params.type === 'place') {
      this.bannerType = 'place';
      searchModule.fetchPlaceSearch(this.params).then(() => {
        this.loadingEnd();
      });
    } else if (to.params.type === 'product') {
      this.bannerType = 'product';
      searchModule.fetchProductSearch(this.params).then(() => {
        this.loadingEnd();
      });
    } else if (to.path === '/interest_place') {
      this.buttonType = 'hidden';
      this.bannerType = 'place';
      this.type = 'place';
      if (this.visited) {
        const params = {
          ids: this.visited,
          is_schedule: 0
        }
        bannerModule.fetchVisitedPlace(params).then(() => {
          this.loadingEnd();
        });
      } else {
        this.loadingEnd();
        console.log('no visited');
      }
    }
    if (this.buttonType === 'default') {
      this.isFilter = (
        this.params.additional_info ||
        this.params.food_type ||
        this.params.area ||
        (this.params && this.params.sort !== 'recommended')
      ) ? true : false;
      if (!this.isFilter) this.filterTag = [];
    }
    next();
  }

  public beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadingStart();
      vm.type = to.params.type;
      vm.params = Object.assign(vm.params, to.query);
      vm.firstRandom = to.query.is_random;

      if (to.query.reserve_date) vm.reserveStatus = true;

      if (to.query.id || to.query.group_id) {
        vm.buttonType = 'single';
        if (to.query.group_id) {
          const params: any = {
            id: to.query.group_id,
            area_id: to.query.area_id,
            geo_location: localStorage.nowLocation
          };
          if (to.query.reserve_date) {
            params.reserve_date = to.query.reserve_date;
            params.party_size = to.query.party_size;
            params.start_time = to.query.start_time;
            params.end_time = to.query.end_time;
          }
          vm.bannerType = 'group';
          bannerModule.fetchDetailBannerGroup(params).then(() => {
            vm.loadingEnd();
          });
        } else {
          const params: any = {
            id: to.query.id,
            is_schedule: 1
          }
          if (to.query.reserve_date) {
            params.reserve_date = to.query.reserve_date;
            params.party_size = to.query.party_size;
            params.start_time = to.query.start_time;
            params.end_time = to.query.end_time;
          }
          vm.bannerType = 'single';
          bannerModule.fetchDetailBanner(params).then(() => {
            vm.loadingEnd();
          })
        }
      } else if (to.params.type === 'place') {
        vm.buttonType = 'default';
        vm.bannerType = 'place';
        searchModule.fetchPlaceSearch(vm.params).then(() => {
          vm.loadingEnd();
        });
      } else if (to.params.type === 'product') {
        vm.buttonType = 'default';
        vm.bannerType = 'product';
        searchModule.fetchProductSearch(vm.params).then(() => {
          vm.loadingEnd();
        });
      } else if (to.path === '/interest_place') {
        vm.buttonType = 'hidden';
        vm.bannerType = 'place';
        vm.type = 'place';
        if (vm.visited) {
          const params = {
            ids: vm.visited,
            is_schedule: 0
          }
          bannerModule.fetchVisitedPlace(params).then(() => {
            vm.loadingEnd();
          });
        } else {
          vm.loadingEnd();
        }
      }
      if (vm.buttonType === 'default') {
        vm.isFilter = (
          vm.params.additional_info ||
          vm.params.food_type ||
          vm.params.area ||
          (vm.params && vm.params.sort !== 'recommended')
        ) ? true : false;
      }
    })
  }

  public beforeDestroy() {
    this.loadingEnd();
  }
  public loadingStart() {
    EventBus.$emit('loadingStatus', true);
    this.loadingStatus = true;
  }
  public loadingEnd() {
    EventBus.$emit('loadingStatus', false);
    this.loadingStatus = false;
  }

  public beforeMount() {
    this.type = this.$route.params.type;
  }

  public reserveDelete() {
    this.deleteReserveData();
    if (this.$route.query.id || this.$route.query.group_id) {
      if (this.$route.query.id) this.$router.push({ name: 'banner', query: { id: this.$route.query.id } });
      else this.$router.push({ name: 'banner', query: { area_id: this.$route.query.area_id, group_id: this.$route.query.group_id } });
    } else {
      this.$router.push({ name: 'banner', params: { type: this.$route.params.type }, query: { area: this.$route.query.area, sort: this.$route.query.sort, schedule_date: this.$route.query.schedule_date, is_random: '1' } });
    }
    // this.$router.push({ name: 'banner' , params: { type: this.$route.params.type }, query: { area_id: this.$route.query.area_id, group_id: this.$route.query.group_id } });
  }

  public deleteReserveData() {
    this.reserveStatus = false;
    this.params.reserve_date = '';
    this.params.party_size = null;
    this.params.start_time = '';
    this.params.end_time = '';
  }

  public filter(v) {
    const filterData = {
      food_type: v.food.join(','),
      additional_info: v.additional.join(','),
      area: v.area.join(','),
      sort: v.sort
    };
    this.filterTag = v.tag;

    let query = Object.assign({}, this.$route.query);
    query = Object.assign(query, filterData);

    if (query.is_random && query.sort !== 'recommended') query.is_random = '0';
    else if (this.firstRandom === '1' && query.sort === 'recommended') query.is_random = '1';

    this.$router.push({ name: 'banner', params: { type: this.$route.params.type }, query });

    this.$modal.hide('poing-filter');
  }

  public $_listMixins_fetch($state: InfiniteHandlerState) {
    if (this.type === 'place') {
      searchModule
        .fetchPlaceSearch(this.params)
        .then((res: any) => {
          if (
            this.placeList &&
            this.placeList.data.length >=
            (this.placeList ? this.placeList.meta.count : 0) ||
            res.data.length < 1
          ) {
            $state.complete();
          } else {
            $state.loaded();
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      searchModule
        .fetchProductSearch(this.params)
        .then((res: any) => {
          if (
            this.productList &&
            this.productList.data.length >=
            (this.productList ? this.productList.meta.count : 0) ||
            res.data.length < 1
          ) {
            $state.complete();
          } else {
            $state.loaded();
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
}
